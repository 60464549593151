'use client';

import { useEffect, useState } from 'react';
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Radio,
  RadioGroup,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react';
import { StarIcon } from '@heroicons/react/20/solid';
import { HeartIcon, MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const products = [
  {
    _id: 1,
    name: 'Δραπανοκατσάβιδο Μπαταρίας Ryobi',
    price: '56',
    rating: 4,
    details: [
      {
        name: 'Χαρακτηριστικά',
        items: ['Επαγγελματική χρήση', '220W', 'Μπαταρίας'],
      },
      // More sections...
    ],
    colors: [
      {
        name: 'Washed Black',
        bgColor: 'bg-gray-700',
        selectedColor: 'ring-gray-700',
      },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      {
        name: 'Washed Gray',
        bgColor: 'bg-gray-500',
        selectedColor: 'ring-gray-500',
      },
    ],
    images: [
      {
        id: 1,
        name: 'Angled view',
        src: 'https://cdn.pixabay.com/photo/2018/09/03/11/21/drill-3650994_1280.png',
        alt: 'Angled front view with bag zipped and handles upright.',
      },
      // More images...
    ],
    description:
      'Το Ryobi 41314 είναι ένα δραπανοκατσάβιδο για ημί-επαγγελματίες με μεγάλη διάρκεια λειτουργίας και υψηλό δείκτη αξιοπιστίας. Διατίθεται μαζί με μπαταρία, ταχυφορτιστή και βαλίτσα μεταφοράς.',
    options: '8 colors',
    imageSrc:
      'https://cdn.pixabay.com/photo/2018/09/03/11/21/drill-3650994_1280.png',
    imageAlt:
      'Eight shirts arranged on table in black, olive, grey, blue, white, red, mustard, and green.',
  },
  {
    _id: 2,
    name: 'Σέγα Dewalt',
    price: '32',
    rating: 4,
    details: [
      {
        name: 'Χαρακτηριστικά',
        items: ['Επαγγελματική χρήση', '220W', 'Ηλεκτρικό'],
      },
      // More sections...
    ],
    colors: [
      {
        name: 'Washed Black',
        bgColor: 'bg-gray-700',
        selectedColor: 'ring-gray-700',
      },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      {
        name: 'Washed Gray',
        bgColor: 'bg-gray-500',
        selectedColor: 'ring-gray-500',
      },
    ],
    images: [
      {
        id: 1,
        name: 'Angled view',
        src: 'https://cdn.pixabay.com/photo/2019/11/15/13/22/battery-4628419_1280.jpg',
        alt: 'Angled front view with bag zipped and handles upright.',
      },
      // More images...
    ],
    description:
      'Το Dewalt 413145 είναι μια σέγα για ημί-επαγγελματίες με υψηλό δείκτη αξιοπιστίας. Διατίθεται μαζί με ταχυφορτιστή και βαλίτσα μεταφοράς.',
    options: 'Black',
    imageSrc:
      'https://cdn.pixabay.com/photo/2019/11/15/13/22/battery-4628419_1280.jpg',
    imageAlt: 'Front of plain black t-shirt.',
  },
  {
    _id: 3,
    name: 'Τρυπάνι Abc123',
    price: '45',
    rating: 4,
    details: [
      {
        name: 'Χαρακτηριστικά',
        items: ['Επαγγελματική χρήση', '220W', 'Ηλεκτρικό'],
      },
      // More sections...
    ],
    colors: [
      {
        name: 'Washed Black',
        bgColor: 'bg-gray-700',
        selectedColor: 'ring-gray-700',
      },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      {
        name: 'Washed Gray',
        bgColor: 'bg-gray-500',
        selectedColor: 'ring-gray-500',
      },
    ],
    images: [
      {
        id: 1,
        name: 'Angled view',
        src: 'https://cdn.pixabay.com/photo/2020/04/10/10/46/bit-5025182_1280.jpg',
        alt: 'Angled front view with bag zipped and handles upright.',
      },
      // More images...
    ],
    description:
      'Το τρυπάνι Abc123 απευθύνεται σε επαγγελματίες το οποίο ανταπεξέρχετε ευκολα στις δύσκολες εργασίες.',
    options: 'Black',
    imageSrc:
      'https://cdn.pixabay.com/photo/2020/04/10/10/46/bit-5025182_1280.jpg',
    imageAlt: 'Front of plain black t-shirt.',
  },
  {
    _id: 4,
    name: 'Μπετονιέρα Kittory',
    price: '80',
    rating: 4,
    details: [
      {
        name: 'Χαρακτηριστικά',
        items: ['Επαγγελματική χρήση', '220W', 'Ηλεκτρικό'],
      },
      // More sections...
    ],
    colors: [
      {
        name: 'Washed Black',
        bgColor: 'bg-gray-700',
        selectedColor: 'ring-gray-700',
      },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      {
        name: 'Washed Gray',
        bgColor: 'bg-gray-500',
        selectedColor: 'ring-gray-500',
      },
    ],
    images: [
      {
        id: 1,
        name: 'Angled view',
        src: 'https://cdn.pixabay.com/photo/2020/01/22/01/57/betonka-4784341_1280.jpg',
        alt: 'Angled front view with bag zipped and handles upright.',
      },
      // More images...
    ],
    description:
      'Επαγγελματική μπετονιέρα Kittory με προηγμένο σύστημα τεχνολογίας.',
    options: 'Black',
    imageSrc:
      'https://cdn.pixabay.com/photo/2020/01/22/01/57/betonka-4784341_1280.jpg',
    imageAlt: 'Front of plain black t-shirt.',
  },
  {
    _id: 5,
    name: 'Καροτιέρα με Βάση',
    price: '95',
    rating: 4,
    details: [
      {
        name: 'Χαρακτηριστικά',
        items: ['Επαγγελματική χρήση', '220W', 'Ηλεκτρικό'],
      },
      // More sections...
    ],
    colors: [
      {
        name: 'Washed Black',
        bgColor: 'bg-gray-700',
        selectedColor: 'ring-gray-700',
      },
      { name: 'White', bgColor: 'bg-white', selectedColor: 'ring-gray-400' },
      {
        name: 'Washed Gray',
        bgColor: 'bg-gray-500',
        selectedColor: 'ring-gray-500',
      },
    ],
    images: [
      {
        id: 1,
        name: 'Angled view',
        src: 'https://caykentools.com/wp-content/uploads/2021/01/Dk-450-1-1-scaled.jpg',
        alt: 'Angled front view with bag zipped and handles upright.',
      },
      // More images...
    ],
    description: 'Καροτιέρα με βάση, με βέλτιστη ταχύτητα εκκίνησης.',
    options: 'Black',
    imageSrc:
      'https://caykentools.com/wp-content/uploads/2021/01/Dk-450-1-1-scaled.jpg',
    imageAlt: 'Front of plain black t-shirt.',
  },
  // More products...
];
const EditStaticDataPage = () => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className='xl:pl-72'>
        <main>
          <h1 className='sr-only'>Δημιουργία Προϊόντος</h1>
          <div className='divide-y divide-white/5'>
            <div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
              <div>
                <h2 className='text-base/7 font-semibold text-white'>
                  Επεξεργασία Στατικών Δεδομένων
                </h2>
                <p className='mt-1 text-sm/6 text-gray-400'>
                  Παρακαλούμε συμπληρώστε όλα τα τα πεδία
                </p>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditStaticDataPage;
