import { LOAD_PRODUCTS, UPDATE_FILTERS, FILTER_PRODUCTS } from '../actions';

const filter_reducer = (state, action) => {
  if (action.type === LOAD_PRODUCTS) {
    return {
      ...state,
      all_products: [...action.payload],
      filtered_products: [...action.payload],
      filters: { ...state.filters },
    };
  }

  if (action.type === UPDATE_FILTERS) {
    const { name, value } = action.payload;
    console.log(name, value);

    return { ...state, filters: { ...state.filters, [name]: value } };
  }
  if (action.type === FILTER_PRODUCTS) {
    const { all_products } = state;
    const { text } = state.filters;
    let tempProducts = [...all_products];
    if (text) {
      console.log(text);

      let tempText = text.toLowerCase();
      tempProducts = tempProducts.filter(
        (product) =>
          product.title.toLowerCase().includes(tempText) ||
          product.category.toLowerCase().includes(tempText) ||
          product.subcategory.toLowerCase().includes(tempText) ||
          product.company.toLowerCase().includes(tempText)
      );
    }

    return { ...state, filtered_products: tempProducts };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default filter_reducer;
