import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUserContext } from '../context/user_context';
import logo from '../assets/free-back.png';
import { useEffect, useState } from 'react';

const LoginPage = () => {
  const { signin, user_error, user, user_loading } = useUserContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    if (id === 'email') {
      setEmail(value);
    }
    if (id === 'password') {
      setPassword(value);
    }
  };
  useEffect(() => {
    if (localStorage.getItem('user')) {
      navigate('/');
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (user_error) {
      //toast.error(user_error);
    }
    // eslint-disable-next-line
  }, [user_error]);

  return (
    <div className='flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8'>
      <div className='sm:mx-auto sm:w-full sm:max-w-sm'>
        <img className='mx-auto h-20 w-auto' src={logo} alt='Your Company' />
        <h2 className='mt-10 text-center text-2xl/9 font-bold tracking-tight text-white'>
          Είσοδος
        </h2>
      </div>

      <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-sm'>
        <form
          className='space-y-6'
          method='POST'
          onSubmit={signin(email, password)}
        >
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-white'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                value={email}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div>
            <div className='flex items-center justify-between'>
              <label
                htmlFor='password'
                className='block text-sm font-medium leading-6 text-white'
              >
                Κωδικός
              </label>
              {/* <div className='text-sm'>
                  <a
                    href='/'
                    className='font-semibold text-neutral-600 hover:text-neutral-500'
                  >
                    Forgot password?
                  </a>
                </div> */}
            </div>
            <div className='mt-2'>
              <input
                id='password'
                name='password'
                type='password'
                autoComplete='current-password'
                required
                className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                value={password}
                onChange={(e) => handleInputChange(e)}
              />
            </div>
          </div>

          <div>
            <button
              type='submit'
              className='flex w-full justify-center rounded-md bg-indigo-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              disabled={user_loading}
            >
              {user_loading ? (
                <span className='loading loading-spinner loading-xs'></span>
              ) : (
                'Είσοδος'
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

// const Wrapper = styled.section`
//   margin: 0;
//   width: 100vw;
//   height: 100vh;
//   background: #ecf0f3;
//   display: flex;
//   align-items: center;
//   text-align: center;
//   justify-content: center;
//   place-items: center;
//   overflow: hidden;
//   font-family: poppins;

//   input {
//     caret-color: red;
//   }

//   .error {
//     color: red;
//   }

//   .container {
//     position: relative;
//     width: 350px;
//     height: 600px;
//     border-radius: 20px;
//     padding: 40px;
//     box-sizing: border-box;
//     background: #ecf0f3;
//     box-shadow: 14px 14px 20px #cbced1, -14px -14px 20px white;
//   }

//   .brand-logo {
//     height: 100px;
//     width: 100px;
//     margin: auto;
//     border-radius: 50%;
//     box-sizing: border-box;
//     box-shadow: 7px 7px 10px #cbced1, -7px -7px 10px white;
//   }

//   .brand-title {
//     margin-top: 10px;
//     font-weight: 900;
//     font-size: 1.8rem;
//     color: #555150;
//     letter-spacing: 1px;
//   }

//   .inputs {
//     text-align: left;
//     margin-top: 30px;
//   }

//   label,
//   input,
//   button {
//     display: block;
//     width: 100%;
//     padding: 0;
//     border: none;
//     outline: none;
//     box-sizing: border-box;
//   }

//   label {
//     margin-bottom: 4px;
//   }

//   label:nth-of-type(2) {
//     margin-top: 12px;
//   }

//   input::placeholder {
//     color: gray;
//   }

//   input {
//     background: #ecf0f3;
//     padding: 10px;
//     padding-left: 20px;
//     height: 50px;
//     font-size: 14px;
//     border-radius: 50px;
//     box-shadow: inset 6px 6px 6px #cbced1, inset -6px -6px 6px white;
//   }

//   button {
//     color: white;
//     margin-top: 20px;
//     background: #555150;
//     height: 40px;
//     border-radius: 20px;
//     cursor: pointer;
//     font-weight: 900;
//     box-shadow: 6px 6px 6px #cbced1, -6px -6px 6px white;
//     transition: 0.5s;
//   }

//   button:hover {
//     box-shadow: none;
//   }

//   p {
//     padding-top: 0.5rem;
//   }

//   /* a {
//     position: absolute;
//     font-size: 8px;
//     bottom: 4px;
//     right: 4px;
//     text-decoration: none;
//     color: black;
//     background: yellow;
//     border-radius: 10px;
//     padding: 2px;
//   } */

//   h1 {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// `;

export default LoginPage;
