import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import {
  CreateProduct,
  CreateUser,
  ListProducts,
  EditProduct,
  Settings,
  EditStaticData,
  Login,
  Error,
} from './pages';
import { Navbar, ScrollToTop } from './components';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path='/' element={<CreateProduct />} />
        {/* <Route path='/createuser' element={<CreateUser />} /> */}
        <Route path='/productslist' element={<ListProducts />} />
        <Route path='/editproduct/:id' element={<EditProduct />} />
        <Route path='/login' element={<Login />} />
        <Route path='/settings' element={<Settings />} />
        <Route path='*' element={<Error />} />
        {/* <Route path='/staticdata' element={<EditStaticData />} /> */}
      </Routes>
      {/* <Footer /> */}
    </Router>
  );
}

export default App;
