import React, { useContext, useEffect, useReducer } from 'react';
import reducer from '../reducers/products_reducer';
import { toast } from 'react-toastify';
import axios from 'axios';
//import { products_url as url } from '../utils/constants';
import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  MOBILE_FILTERS_SIDEBAR_OPEN,
  MOBILE_FILTERS_SIDEBAR_CLOSE,
  CREATE_PRODUCT_BEGIN,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  UPDATE_TITLE_INFO,
  UPDATE_COMPANY_INFO,
  UPDATE_CATEGORY_INFO,
  UPDATE_SUBCATEGORY_INFO,
  UPDATE_DESCRIPTION_INFO,
  UPDATE_IMAGES_INFO,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  UPDATE_PRODUCT_BEGIN,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT_BEGIN,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
} from '../actions';
//import { products_url } from '../utils/constants';
import { useParams } from 'react-router-dom';
const products_url = process.env.REACT_APP_PRODUCTS_URL;
const initialState = {
  isSidebarOpen: false,
  isMobileFiltersSidebarOpen: false,
  product_create_loading: false,
  product_create_error: false,
  product_created: false,
  product_delete_loading: false,
  product_delete_error: false,
  product_deleted: false,
  delete_product_message: '',
  product_update_loading: false,
  product_update_error: false,
  product_updated: false,
  product: null,
  updated_product: null,
  products_loading: false,
  products_error: false,
  products: [],
  title: '',
  company: '',
  category: '',
  subcategory: '',
  description: '',
  single_product_loading: false,
  single_product_error: false,
  single_product: {},
};
const ProductsContext = React.createContext();

export const ProductsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { id } = useParams();
  const openSidebar = () => {
    dispatch({ type: SIDEBAR_OPEN });
  };
  const closeSidebar = () => {
    dispatch({ type: SIDEBAR_CLOSE });
  };

  const openMobileFilters = () => {
    dispatch({ type: MOBILE_FILTERS_SIDEBAR_OPEN });
  };
  const closeMobileFilters = () => {
    dispatch({ type: MOBILE_FILTERS_SIDEBAR_CLOSE });
  };

  const updateTitleInfo = (event) => {
    const value = event.target.value;
    console.log(value);
    dispatch({ type: UPDATE_TITLE_INFO, payload: value });
  };
  const updateImagesInfo = (event) => {
    const files = event.target.files;
    let value = [];
    value = files;
    console.log(value);
    dispatch({ type: UPDATE_IMAGES_INFO, payload: value });
  };
  const updateCompanyInfo = (event) => {
    const value = event.target.value;
    console.log(value);
    dispatch({ type: UPDATE_COMPANY_INFO, payload: value });
  };
  const updateCategoryInfo = (event) => {
    const value = event.target.value;
    console.log(value);
    dispatch({ type: UPDATE_CATEGORY_INFO, payload: value });
  };
  const updateSubcategoryInfo = (event) => {
    const value = event.target.value;
    console.log(value);
    dispatch({ type: UPDATE_SUBCATEGORY_INFO, payload: value });
  };
  const updateDescriptionInfo = (event) => {
    const value = event.target.value;
    console.log(value);
    dispatch({ type: UPDATE_DESCRIPTION_INFO, payload: value });
  };

  const fetchAllProducts = async (url) => {
    dispatch({ type: GET_PRODUCTS_BEGIN });
    try {
      const response = await axios.get(url);
      console.log(response);
      const products = response.data.products;
      dispatch({ type: GET_PRODUCTS_SUCCESS, payload: products });
    } catch (error) {
      dispatch({ type: GET_PRODUCTS_ERROR });
    }
  };
  const deleteProduct = async (url) => {
    dispatch({ type: DELETE_PRODUCT_BEGIN });
    try {
      const response = await axios.delete(url, {
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
      });
      const message = response.data.msg;
      toast.success(message);
      console.log(response);
      dispatch({ type: DELETE_PRODUCT_SUCCESS, payload: message });
      setTimeout(() => {
        window.location.reload();
      }, '3000');
    } catch (error) {
      toast.success(error.response.data.msg);
      dispatch({
        type: DELETE_PRODUCT_ERROR,
        payload: error.response.data.msg,
      });
      console.log(error);
    }
  };

  const fetchSingleProduct = async (url) => {
    dispatch({ type: GET_SINGLE_PRODUCT_BEGIN });
    try {
      const response = await axios.get(url);
      const singleProduct = response.data.product;
      //console.log('single');
      // console.log(singleProduct);
      dispatch({ type: GET_SINGLE_PRODUCT_SUCCESS, payload: singleProduct });
      return response.data.product;
    } catch (error) {
      dispatch({
        type: GET_SINGLE_PRODUCT_ERROR,
        payload: error.response.data.msg,
      });
    }
  };

  const createProduct = (formData) => async (event) => {
    dispatch({ type: CREATE_PRODUCT_BEGIN });
    event.preventDefault();
    try {
      const response = await axios.post(`${products_url}`, formData, {
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });
      const product = response.data.product;
      toast.success('Το Προϊόν Δημιουργήθηκε Επιτυχώς!');
      dispatch({ type: CREATE_PRODUCT_SUCCESS, payload: product });

      // if (order) {
      //   return res.redirect('/home');
      // }
    } catch (error) {
      toast.error(error.response.data.msg);
      dispatch({
        type: CREATE_PRODUCT_ERROR,
        payload: error.response.data.msg,
      });
    }
  };

  const updateProduct = (formData, id) => async (event) => {
    dispatch({ type: UPDATE_PRODUCT_BEGIN });
    event.preventDefault();
    try {
      const response = await axios.patch(`${products_url}${id}`, formData, {
        withCredentials: true,
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log(id);

      const product = response.data.product;
      toast.success('Το Προϊόν Ενημερώθηκε Επιτυχώς!');
      dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: product });

      // if (order) {
      //   return res.redirect('/home');
      // }
    } catch (error) {
      toast.error(error.response.data.msg);
      dispatch({
        type: UPDATE_PRODUCT_ERROR,
        payload: error.response.data.msg,
      });
    }
  };

  useEffect(() => {
    fetchAllProducts(`${products_url}`);
  }, []);
  return (
    <ProductsContext.Provider
      value={{
        ...state,
        openSidebar,
        closeSidebar,
        openMobileFilters,
        closeMobileFilters,
        createProduct,
        updateTitleInfo,
        updateCompanyInfo,
        updateCategoryInfo,
        updateSubcategoryInfo,
        updateDescriptionInfo,
        updateImagesInfo,
        fetchAllProducts,
        fetchSingleProduct,
        updateProduct,
        deleteProduct,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};
// make sure use
export const useProductsContext = () => {
  return useContext(ProductsContext);
};
