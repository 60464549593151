import React, { useEffect, useState } from 'react';
import { FiTruck, FiTool, FiUsers, FiSettings } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/user_context';

const SettingsPage = () => {
  const navigate = useNavigate();
  const { user, password_update_loading, updatePassword } = useUserContext();
  const currenUser = Array(user);
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [comparePasswords, setComparePasswords] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    if (newPassword !== confirmNewPassword) {
      setComparePasswords('Οι Κωδικοί δεν ταιριάζουν');
      setIsDisabled(true);
    } else {
      setComparePasswords('');
      setIsDisabled(false);
    }
    // eslint-disable-next-line
  }, [newPassword, confirmNewPassword]);

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
    }
    console.log(currentPassword);

    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className='xl:pl-72'>
        <main>
          <h1 className='sr-only'>Επεξεργασία Λογαριασμού</h1>
          <div className='divide-y divide-white/5'>
            <div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
              <div>
                <h2 className='text-base/7 font-semibold text-white'>
                  Επεξεργασία Λογαριασμού
                </h2>
                <p className='mt-1 text-sm/6 text-gray-400'>
                  Παρακαλούμε συμπληρώστε όλα τα τα πεδία
                </p>
              </div>
              <form
                className='md:col-span-2'
                onSubmit={updatePassword(currentPassword, newPassword)}
              >
                <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6'>
                  <div className='col-span-full'>
                    <label
                      htmlFor='title'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Email (Δεν μπορεί να αλλάξει)
                    </label>
                    <div className='mt-2'>
                      <input
                        id='email'
                        name='email'
                        type='text'
                        value={currenUser[0]?.email}
                        autoComplete='off'
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        disabled
                      />
                    </div>
                  </div>

                  <div className='col-span-full'>
                    <label
                      htmlFor='title'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Τρέχων Κωδικός
                    </label>
                    <div className='mt-2'>
                      <input
                        id='currentPassword'
                        name='currentPassword'
                        type='password'
                        value={currentPassword}
                        onChange={(e) => setCurrentPassword(e.target.value)}
                        autoComplete='off'
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Νέος Κωδικός
                    </label>
                    <div className='mt-2'>
                      <input
                        id='newPassword'
                        name='newPassword'
                        type='password'
                        autoComplete='off'
                        minLength={6}
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Επιβεβαίωσει Νέου Κωδικού
                    </label>
                    <div className='mt-2'>
                      <input
                        id='confirmNewPassword'
                        name='confirmNewPassword'
                        type='password'
                        autoComplete='off'
                        minLength={6}
                        value={confirmNewPassword}
                        onChange={(e) => setConfirmNewPassword(e.target.value)}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className='flex self-end mt-3'>
                  {comparePasswords !== newPassword &&
                  confirmNewPassword !== '' &&
                  newPassword !== '' ? (
                    <>
                      <span
                        className={`${
                          confirmNewPassword !== newPassword &&
                          confirmNewPassword !== '' &&
                          newPassword !== ''
                            ? 'block items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10 '
                            : ''
                        }`}
                      >
                        {comparePasswords}
                      </span>
                    </>
                  ) : (
                    <span className='invisible'></span>
                  )}
                </div>
                <div className='mt-8 flex self-end'>
                  <button
                    type='submit'
                    className='rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold self-end text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                    disabled={password_update_loading | isDisabled}
                  >
                    {password_update_loading ? (
                      <span className='loading loading-spinner loading-xs'></span>
                    ) : (
                      'Ενημέρωση Κωδικού'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default SettingsPage;
