'use client';

import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  TransitionChild,
} from '@headlessui/react';

import {
  Cog6ToothIcon,
  GlobeAltIcon,
  XMarkIcon,
  PlusIcon,
  PencilIcon,
  UserPlusIcon,
  ChartPieIcon,
  PowerIcon,
} from '@heroicons/react/24/outline';
import { Bars3Icon, MagnifyingGlassIcon } from '@heroicons/react/20/solid';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import logo from '../assets/MYGI RENTAL LOGO.jpg';
import LoginPage from '../pages/LoginPage';
import { useUserContext } from '../context/user_context';
import { useFilterContext } from '../context/filter_context';
const navigation = [
  { name: 'Δημιουργία Προϊόντος', href: '/', icon: PlusIcon },
  { name: 'Επεξεργασία Προϊόντων', href: '/productslist', icon: PencilIcon },
  // { name: 'Δημιουργία Χρήστη', href: '/createuser', icon: UserPlusIcon },
  // { name: 'Στατικά Δεδομένα', href: '/staticdata', icon: GlobeAltIcon },
  // { name: 'Στατιστικά', href: '/statisticdata', icon: ChartPieIcon },
  { name: 'Ρυθμίσεις', href: '/settings', icon: Cog6ToothIcon },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const Nav = () => {
  const { user, logout } = useUserContext();
  const currenUser = Array(user);
  const {
    filters: { text },
    updateFilters,
  } = useFilterContext();
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (!user[0]?.email) {
  //     localStorage.removeItem('user');
  //   }
  // }, []);

  useEffect(() => {
    if (text) {
      navigate('/productslist');
    }
  }, [text]);
  let location = useLocation();
  const { id } = useParams();
  let currentpath = location.pathname;
  console.log('id:' + id);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  // if (!localStorage.getItem('user')) {
  //   return navigate('/login');
  // } else {
  return (
    <>
      <div>
        <Dialog
          open={sidebarOpen}
          onClose={setSidebarOpen}
          className='relative z-50 xl:hidden'
        >
          <DialogBackdrop
            transition
            className='fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0'
          />

          <div className='fixed inset-0 flex'>
            <DialogPanel
              transition
              className='relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full'
            >
              <TransitionChild>
                <div className='absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0'>
                  <button
                    type='button'
                    onClick={() => setSidebarOpen(false)}
                    className='-m-2.5 p-2.5'
                  >
                    <span className='sr-only'>Close sidebar</span>
                    <XMarkIcon
                      aria-hidden='true'
                      className='h-6 w-6 text-white'
                    />
                  </button>
                </div>
              </TransitionChild>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-gray-900 px-6 ring-1 ring-white/10'>
                <div className='flex h-16 shrink-0 items-center'>
                  <img
                    alt='Your Company'
                    src={logo}
                    className='h-8 w-auto rounded-full'
                  />
                </div>
                <nav className='flex flex-1 flex-col'>
                  <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                    <li>
                      <ul role='list' className='-mx-2 space-y-1'>
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                currentpath === item.href
                                  ? 'bg-gray-800 text-white'
                                  : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                                'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                              )}
                            >
                              <item.icon
                                aria-hidden='true'
                                className='h-6 w-6 shrink-0'
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>

                    <li className='-mx-6 mt-auto'>
                      <a
                        href='#'
                        className='flex items-center gap-x-4 px-6 py-3 text-sm/6 font-semibold text-white hover:bg-gray-800'
                      >
                        <img
                          alt=''
                          src='https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                          className='h-8 w-8 rounded-full bg-gray-800'
                        />
                        <span className='sr-only'>Your profile</span>
                        <span aria-hidden='true'></span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DialogPanel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className='hidden xl:fixed xl:inset-y-0 xl:z-50 xl:flex xl:w-72 xl:flex-col'>
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className='flex grow flex-col gap-y-5 overflow-y-auto bg-black/10 px-6 ring-1 ring-white/5'>
            <div className='flex h-16 shrink-0 items-center'>
              <img
                alt='Your Company'
                src={logo}
                className='h-8 w-8 rounded-full'
              />
            </div>
            <nav className='flex flex-1 flex-col'>
              <ul role='list' className='flex flex-1 flex-col gap-y-7'>
                <li>
                  <ul role='list' className='-mx-2 space-y-1'>
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            currentpath === item.href
                              ? 'bg-gray-800 text-white'
                              : 'text-gray-400 hover:bg-gray-800 hover:text-white',
                            'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                          )}
                        >
                          <item.icon
                            aria-hidden='true'
                            className='h-6 w-6 shrink-0'
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                    {currenUser[0]?.name ? (
                      <li key='logout'>
                        <button
                          className={classNames(
                            'text-gray-400 hover:bg-gray-800 hover:text-red-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm/6 font-semibold'
                          )}
                          onClick={() => {
                            localStorage.removeItem('user');
                            logout();
                          }}
                        >
                          <PowerIcon
                            aria-hidden='true'
                            className='h-6 w-6 shrink-0'
                          />
                          Αποσύνδεση
                        </button>
                      </li>
                    ) : (
                      <></>
                    )}
                  </ul>
                </li>

                <li className='-mx-6 mt-auto'>
                  {currenUser?.map((user) => (
                    <Link
                      href='#'
                      className='flex items-center gap-x-4 px-6 py-3 text-sm/6 font-semibold text-white hover:bg-gray-800'
                    >
                      <img
                        alt=''
                        className='w-10 h-10 rounded-full'
                        src={logo}
                      />
                      <span className='sr-only'>Your profile</span>
                      <span key={user?.name} aria-hidden='true'>
                        {user?.email}
                      </span>
                    </Link>
                  ))}
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className='xl:pl-72'>
          {/* Sticky search header */}
          <div className='sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-6 border-b border-white/5 bg-gray-900 px-4 shadow-sm sm:px-6 lg:px-8'>
            <button
              type='button'
              onClick={() => setSidebarOpen(true)}
              className='-m-2.5 p-2.5 text-white xl:hidden'
            >
              <span className='sr-only'>Open sidebar</span>
              <Bars3Icon aria-hidden='true' className='h-5 w-5' />
            </button>

            <div className='flex flex-1 gap-x-4 self-stretch lg:gap-x-6'>
              <form
                onSubmit={(e) => e.preventDefault()}
                className='flex flex-1'
              >
                <label className='sr-only'>Search</label>
                <div className='relative w-full'>
                  <MagnifyingGlassIcon
                    aria-hidden='true'
                    className='pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-500'
                  />
                  <input
                    type='text'
                    name='text'
                    value={text}
                    onChange={updateFilters}
                    placeholder='Search...'
                    className='block h-full w-full border-0 bg-transparent py-0 pl-8 pr-0 text-white focus:ring-0 sm:text-sm'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  // }
};

export default Nav;
