import {
  SIDEBAR_OPEN,
  SIDEBAR_CLOSE,
  MOBILE_FILTERS_SIDEBAR_OPEN,
  MOBILE_FILTERS_SIDEBAR_CLOSE,
  CREATE_PRODUCT_BEGIN,
  CREATE_PRODUCT_SUCCESS,
  CREATE_PRODUCT_ERROR,
  UPDATE_TITLE_INFO,
  UPDATE_COMPANY_INFO,
  UPDATE_CATEGORY_INFO,
  UPDATE_SUBCATEGORY_INFO,
  UPDATE_DESCRIPTION_INFO,
  UPDATE_IMAGES_INFO,
  GET_PRODUCTS_BEGIN,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_ERROR,
  GET_SINGLE_PRODUCT_BEGIN,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_ERROR,
  UPDATE_PRODUCT_BEGIN,
  UPDATE_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_ERROR,
  DELETE_PRODUCT_BEGIN,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_ERROR,
  UPDATE_SEARCH,
} from '../actions';

const products_reducer = (state, action) => {
  if (action.type === SIDEBAR_OPEN) {
    return { ...state, isSidebarOpen: true };
  }
  if (action.type === SIDEBAR_CLOSE) {
    return { ...state, isSidebarOpen: false };
  }
  if (action.type === MOBILE_FILTERS_SIDEBAR_OPEN) {
    return { ...state, isMobileFiltersSidebarOpen: true };
  }
  if (action.type === MOBILE_FILTERS_SIDEBAR_CLOSE) {
    return { ...state, isMobileFiltersSidebarOpen: false };
  }
  if (action.type === UPDATE_IMAGES_INFO) {
    return { ...state, images: action.payload };
  }
  if (action.type === UPDATE_TITLE_INFO) {
    return { ...state, title: action.payload };
  }
  if (action.type === UPDATE_COMPANY_INFO) {
    return { ...state, company: action.payload };
  }
  if (action.type === UPDATE_CATEGORY_INFO) {
    return { ...state, category: action.payload };
  }
  if (action.type === UPDATE_SUBCATEGORY_INFO) {
    return { ...state, subcategory: action.payload };
  }
  if (action.type === UPDATE_DESCRIPTION_INFO) {
    return { ...state, description: action.payload };
  }
  if (action.type === GET_PRODUCTS_BEGIN) {
    return { ...state, products_loading: true };
  }
  if (action.type === GET_PRODUCTS_SUCCESS) {
    const featured_products = action.payload.filter(
      (product) => product.featured === true
    );
    return {
      ...state,
      products_loading: false,
      products: action.payload,
      featured_products,
    };
  }
  if (action.type === GET_PRODUCTS_ERROR) {
    return { ...state, products_loading: false, products_error: true };
  }

  if (action.type === CREATE_PRODUCT_BEGIN) {
    return {
      ...state,
      product_create_loading: true,
      product_create_error: false,
    };
  }
  if (action.type === CREATE_PRODUCT_SUCCESS) {
    return {
      ...state,
      // title: '',
      // company: '',
      // category: '',
      // subcategory: '',
      // description: '',
      product_create_loading: false,
      product_created: true,
      product: action.payload,
    };
  }
  if (action.type === CREATE_PRODUCT_ERROR) {
    return {
      ...state,
      product_create_loading: false,
      product_create_error: action.payload,
    };
  }
  if (action.type === UPDATE_PRODUCT_BEGIN) {
    return {
      ...state,
      product_update_loading: true,
      product_update_error: false,
    };
  }
  if (action.type === UPDATE_PRODUCT_SUCCESS) {
    return {
      ...state,
      // title: '',
      // company: '',
      // category: '',
      // subcategory: '',
      // description: '',
      product_update_loading: false,
      product_updated: true,
      updated_product: action.payload,
    };
  }
  if (action.type === UPDATE_PRODUCT_ERROR) {
    return {
      ...state,
      product_update_loading: false,
      product_update_error: action.payload,
    };
  }
  if (action.type === UPDATE_PRODUCT_BEGIN) {
    return {
      ...state,
      product_update_loading: true,
      product_update_error: false,
    };
  }
  if (action.type === UPDATE_PRODUCT_SUCCESS) {
    return {
      ...state,
      // title: '',
      // company: '',
      // category: '',
      // subcategory: '',
      // description: '',
      product_update_loading: false,
      product_updated: true,
      updated_product: action.payload,
    };
  }
  if (action.type === UPDATE_PRODUCT_ERROR) {
    return {
      ...state,
      product_update_loading: false,
      product_update_error: action.payload,
    };
  }
  if (action.type === UPDATE_PRODUCT_BEGIN) {
    return {
      ...state,
      product_update_loading: true,
      product_update_error: false,
    };
  }
  if (action.type === UPDATE_PRODUCT_SUCCESS) {
    return {
      ...state,
      // title: '',
      // company: '',
      // category: '',
      // subcategory: '',
      // description: '',
      product_update_loading: false,
      product_updated: true,
      updated_product: action.payload,
    };
  }
  if (action.type === UPDATE_PRODUCT_ERROR) {
    return {
      ...state,
      product_update_loading: false,
      product_update_error: action.payload,
    };
  }
  if (action.type === DELETE_PRODUCT_BEGIN) {
    return {
      ...state,
      product_delete_loading: true,
      product_delete_error: false,
    };
  }
  if (action.type === DELETE_PRODUCT_SUCCESS) {
    return {
      ...state,
      product_delete_loading: false,
      product_deleted: true,
      delete_product_message: action.payload,
    };
  }
  if (action.type === DELETE_PRODUCT_ERROR) {
    return {
      ...state,
      product_delete_loading: false,
      product_delete_error: true,
      delete_product_message: action.payload,
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_BEGIN) {
    return {
      ...state,
      single_product_loading: true,
      single_product_error: false,
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_SUCCESS) {
    return {
      ...state,
      single_product_loading: false,
      single_product: action.payload,
    };
  }
  if (action.type === GET_SINGLE_PRODUCT_ERROR) {
    return {
      ...state,
      single_product_loading: false,
      single_product_error: true,
    };
  }
  throw new Error(`No Matching "${action.type}" - action type`);
};

export default products_reducer;
