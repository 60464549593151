import React from 'react';
import { Link } from 'react-router-dom';

const ErrorPage = () => {
  return (
    <div className='xl:pl-72'>
      <main className='grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8'>
        <div className='text-center'>
          <p className='text-base font-semibold text-indigo-600'>404</p>
          <h1 className='mt-4 text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl'>
            Η Σελίδα δεν βρεθηκε
          </h1>

          <div className='mt-10 flex items-center justify-center gap-x-6'>
            <Link
              to={'/'}
              className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            >
              Δημιουργία Προϊόντος
            </Link>
            <Link
              to={'/productslis'}
              className='text-sm font-semibold text-white'
            >
              Επεξεργασία Προϊόντων <span aria-hidden='true'>&rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default ErrorPage;
