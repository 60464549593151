import React, { useContext, useEffect, useState, useReducer } from 'react';
import reducer from '../reducers/user_reducer';
import { toast } from 'react-toastify';
import axios from 'axios';
//import { auth_url, user_url } from '../utils/constants';

import {
  USER_SIGNIN_BEGIN,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_ERROR,
  USER_REGISTER_BEGIN,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from '../actions';
const auth_url = process.env.REACT_APP_AUTH_URL;
const user_url = process.env.REACT_APP_USER_URL;
const initialState = {
  user_loading: false,
  user_error: false,
  user_register_loading: false,
  user_register_error: false,
  password_update_loading: false,
  password_update_error: false,
  password_updated_message: null,
  user: [],
};

const UserContext = React.createContext();
export const UserProvider = ({ children }) => {
  axios.defaults.withCredentials = true;
  const [state, dispatch] = useReducer(reducer, initialState);

  const [myUser, setMyUser] = useState(null);

  const signin = (email, password) => async (event) => {
    dispatch({ type: USER_SIGNIN_BEGIN });
    event.preventDefault();
    const data = {
      email,
      password,
    };

    try {
      const response = await axios.post(`${auth_url}login`, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      localStorage.setItem('user', JSON.stringify(response.data.user));
      const user = response.data.user;
      //setMyUser(user);
      console.log('login' + user);
      toast.success('Συνδεθήκατε Επιτυχώς!');
      setTimeout(() => {
        window.location.reload();
      }, '3000');
      dispatch({ type: USER_SIGNIN_SUCCESS, payload: user });
    } catch (error) {
      toast.error(error.response.data.msg);
      dispatch({
        type: USER_SIGNIN_ERROR,
        payload: error.response.data.msg,
      });
    }
  };

  const register = (name, email, password) => async (event) => {
    dispatch({ type: USER_REGISTER_BEGIN });
    event.preventDefault();
    const data = {
      name,
      email,
      password,
    };
    try {
      const response = await axios.post(`${auth_url}register`, data);
      const user = response.data.user;
      toast.success('Ο Λογαρισμός σας Δημιουργήθηκε Επιτυχώς!');
      setTimeout(() => {
        window.location.reload();
      }, '2000');
      dispatch({ type: USER_REGISTER_SUCCESS, payload: user });
    } catch (error) {
      dispatch({ type: USER_REGISTER_ERROR, payload: error.response.data.msg });
    }
  };
  const updatePassword = (currentPassword, newPassword) => async (event) => {
    dispatch({ type: UPDATE_PASSWORD_BEGIN });
    event.preventDefault();
    const data = {
      currentPassword,
      newPassword,
    };
    try {
      const response = await axios.patch(
        `${user_url}updateUserPassword`,
        data,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        }
      );
      const message = response.data.msg;
      toast.success(
        message +
          'Παρακαλούμε αποσυνδεθείται και ξανασυνδεθείτε με τους νέους κωδικούς!'
      );
      // window.location.reload();
      // if (message) {
      //   await logout();
      // }
      dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: message });
    } catch (error) {
      toast.error(error.response.data.msg);
      dispatch({
        type: UPDATE_PASSWORD_ERROR,
        payload: error.response.data.msg,
      });
    }
  };
  const logout = async () => {
    try {
      const response = await axios.get(`${auth_url}logout`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      toast.success('Αποσυνδεθήκατε Επιτυχώς!');
      setTimeout(() => {
        window.location.reload();
      }, '2000');
    } catch (error) {
      toast.error(error.response.data.msg);
    }
  };

  const showMe = async () => {
    try {
      const response = await axios.get(`${user_url}showMe`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          credentials: 'include',
        },
      });
      const user = response.data.user;
      console.log('showme:' + user);

      dispatch({ type: USER_SIGNIN_SUCCESS, payload: user });
      console.log(response);
    } catch (error) {
      localStorage.removeItem('user');
    }
  };

  useEffect(() => {
    if (localStorage.getItem('user') && initialState.user) {
      setMyUser(localStorage.getItem('user'));
      showMe();
      console.log('eff:' + initialState.user);
    }
  }, []);

  return (
    <UserContext.Provider
      value={{ ...state, signin, register, showMe, logout, updatePassword }}
    >
      {children}
    </UserContext.Provider>
  );
};
// make sure use
export const useUserContext = () => {
  return useContext(UserContext);
};
