'use client';

import { PhotoIcon, PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import { SlTrash } from 'react-icons/sl';
import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useProductsContext } from '../context/products_context';

// const secondaryNavigation = [
//   { name: 'Account', href: '#', current: true },
//   { name: 'Notifications', href: '#', current: false },
//   { name: 'Billing', href: '#', current: false },
//   { name: 'Teams', href: '#', current: false },
//   { name: 'Integrations', href: '#', current: false },
// ];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const CreateProductPage = () => {
  const navigate = useNavigate();
  const {
    //   title,
    //   company,
    //   category,
    //   subcategory,
    //   description,
    //   images,
    //   updateImagesInfo,
    //   updateTitleInfo,
    //   updateCompanyInfo,
    //   updateCategoryInfo,
    //   updateSubcategoryInfo,
    //   updateDescriptionInfo,
    product_create_loading,
    product_create_error,
    product_created,
    createProduct,
  } = useProductsContext();
  const data = new FormData();

  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState([]);

  data.append('title', title);
  data.append('category', category);
  data.append('subcategory', subcategory);
  data.append('description', description);
  data.append('company', company);
  data.append('details', details);
  //data.append('images', images);

  const handleImages = () => {
    for (let i = 0; i < Array.from(images).length; i++) {
      data.append('images', images[i]);
    }
  };
  console.log(data.getAll('images'));

  const updateImagesInfo = (event) => {
    setImages(event.target.files);
    console.log(event.target.files);
    //console.log(URL.createObjectURL(atob(event.target.files[0])));
  };
  const updateTitleInfo = (event) => {
    const value = event.target.value;

    setTitle(value);
  };
  const updateCompanyInfo = (event) => {
    const value = event.target.value;
    setCompany(value);
  };
  const updateCategoryInfo = (event) => {
    const value = event.target.value;
    setCategory(value);
  };
  const updateSubcategoryInfo = (event) => {
    const value = event.target.value;
    setSubcategory(value);
  };
  const updateDescriptionInfo = (event) => {
    const value = event.target.value;
    setDescription(value);
  };

  const handleAddDetails = (e) => {
    setDetails([...details, e.target.value]);
    console.log(e.target.value);
  };
  const handleDeleteDetails = (i) => {
    const deletedValue = [...details];
    deletedValue.splice(i, 1);
    setDetails(deletedValue);
  };
  const handleChangeDetails = (e, i) => {
    const onChangeValue = [...details];
    onChangeValue[i] = e.target.value;
    setDetails(onChangeValue);
  };

  const handleSubmit = () => {
    setImages([]);
    setDetails([]);
    setTitle('');
    setCategory('');
    setSubcategory('');
    setCompany('');
    setDescription('');
  };

  useEffect(() => {
    // for (let i = 0; i < Array.from(images).length; i++) {
    //   data.append('images', images[i]);
    // }
    handleImages();
    console.log(data.getAll('images'));
    // eslint-disable-next-line
  }, [images, title, category, subcategory, company, description, details]);

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
      console.log('login check');
    }
    if (product_created) {
      handleSubmit();
    }
    // eslint-disable-next-line
  }, [product_created]);

  return (
    <div>
      <div className='xl:pl-72'>
        <main>
          <h1 className='sr-only'>Δημιουργία Προϊόντος</h1>
          <div className='divide-y divide-white/5'>
            <div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
              <div>
                <h2 className='text-base/7 font-semibold text-white'>
                  Δημιουργία Προϊόντος
                </h2>
                <p className='mt-1 text-sm/6 text-gray-400'>
                  Παρακαλούμε συμπληρώστε όλα τα τα πεδία.
                </p>
              </div>

              <form className='md:col-span-2' onSubmit={createProduct(data)}>
                <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6'>
                  <div className='col-span-full'>
                    <label
                      htmlFor='title'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Τίτλος
                    </label>
                    <div className='mt-2'>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        autoComplete='off'
                        value={title}
                        onChange={updateTitleInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='col-span-full'>
                    <label
                      htmlFor='title'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Μάρκα
                    </label>
                    <div className='mt-2'>
                      <input
                        id='company'
                        name='company'
                        type='text'
                        autoComplete='off'
                        value={company}
                        onChange={updateCompanyInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Κατηγορία
                    </label>
                    <div className='mt-2'>
                      <input
                        id='category'
                        name='category'
                        type='text'
                        autoComplete='off'
                        value={category}
                        onChange={updateCategoryInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Υποκατηγορία
                    </label>
                    <div className='mt-2'>
                      <input
                        id='subcategory'
                        name='subcategory'
                        type='text'
                        autoComplete='off'
                        value={subcategory}
                        onChange={updateSubcategoryInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='details'
                      className='block text-sm/6 font-medium text-white mb-2'
                    >
                      Χαρακτηριστικά
                    </label>
                    <button
                      type='button'
                      onClick={handleAddDetails}
                      className='rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    >
                      <PlusIcon aria-hidden='true' className='h-5 w-5' />
                    </button>
                    {details.map((detail, i) => (
                      <div className='mt-2 flex'>
                        <div className='flex-auto w-56'>
                          <input
                            id='details'
                            name='details'
                            type='text'
                            key={i}
                            autoComplete='off'
                            value={detail}
                            onChange={(e) => handleChangeDetails(e, i)}
                            className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                            required
                          />
                        </div>
                        <div className='flex-auto'>
                          <button
                            type='button'
                            className='ml-6 rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                            onClick={() => handleDeleteDetails(i)}
                          >
                            <SlTrash aria-hidden='true' className='h-5 w-5' />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className='col-span-full'>
                    <label
                      htmlFor='about'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Περιγραφή
                    </label>
                    <div className='mt-2'>
                      <textarea
                        id='description'
                        name='description'
                        rows={5}
                        value={description}
                        onChange={updateDescriptionInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                    <p className='mt-3 text-sm/6 text-gray-400'>
                      Συμπληρώστε το πεδίο με αναλυτική περιγραφή του προϊόντος.
                    </p>
                  </div>

                  <div className='col-span-full'>
                    <label className='block text-sm/6 font-medium text-white'>
                      <span>
                        {images.length <= 0
                          ? 'Πρακαλώ επιλέξτε φωτογραφίες'
                          : Array.from(images).map((image) => {
                              return (
                                <>
                                  {image.name}
                                  <br />
                                </>
                              );
                            })}
                      </span>
                    </label>
                    <div className='mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10'>
                      <div className='text-center'>
                        <PhotoIcon
                          aria-hidden='true'
                          className='mx-auto h-12 w-12 text-gray-500'
                        />
                        <div className='mt-4 flex text-sm/6 text-gray-400'>
                          <label
                            htmlFor='images'
                            className='relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500'
                          >
                            <span>Ανεβάστε αρχείο</span>
                            <input
                              id='images'
                              name='images'
                              type='file'
                              className='sr-only'
                              onChange={updateImagesInfo}
                              multiple
                              required
                            />
                          </label>
                          <p className='pl-1'>or drag and drop</p>
                        </div>
                        <p className='text-xs/5 text-gray-400'>
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-8 flex self-end'>
                  <button
                    type='submit'
                    className='rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold self-end text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                    disabled={
                      product_create_loading | (Array.from(images).length < 0)
                    }
                  >
                    {product_create_loading ? (
                      <span className='loading loading-spinner loading-xs'></span>
                    ) : (
                      'Δημιουργία Προϊόντος'
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default CreateProductPage;
