import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useProductsContext } from '../context/products_context';
import { useFilterContext } from '../context/filter_context';

const ListProductsPage = () => {
  //const { products } = useProductsContext();
  const { filtered_products: products } = useFilterContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div className='xl:pl-72'>
        <main>
          <div className='px-4 sm:px-6 lg:px-8'>
            <div className='sm:flex sm:items-center'>
              <div className='sm:flex-auto'>
                <h1 className='text-base font-semibold text-white'>
                  Λίστα Προϊόντων
                </h1>
                <p className='mt-2 text-sm text-white'>
                  Επιλέξτε προίόν προς επεξεργασία
                </p>
              </div>
              <div className='mt-4 sm:ml-16 sm:mt-0 sm:flex-none'>
                <Link
                  to={'/'}
                  className='block rounded-md bg-indigo-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                >
                  Δημιουργία Προϊόντος
                </Link>
              </div>
            </div>
            <div className='mt-8 flow-root'>
              <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
                  <table className='min-w-full divide-y divide-gray-300'>
                    <thead>
                      <tr>
                        <th
                          scope='col'
                          className='py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-white sm:pl-0'
                        >
                          Τίτλος
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-white'
                        >
                          Κατηγορία
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-white'
                        >
                          Υποκατηγορία
                        </th>
                        <th
                          scope='col'
                          className='px-3 py-3.5 text-left text-sm font-semibold text-white'
                        >
                          Μάρκα
                        </th>
                        <th
                          scope='col'
                          className='relative py-3.5 pl-3 pr-4 sm:pr-0'
                        >
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='divide-y divide-gray-200 bg-gray-900'>
                      {products.map((product) => (
                        <tr key={product._id}>
                          <td className='whitespace-nowrap py-5 pl-4 pr-3 text-sm sm:pl-0'>
                            <div className='flex items-center'>
                              <div className='h-11 w-11 shrink-0'>
                                <img
                                  alt={product.name}
                                  src={product.images[0].url}
                                  className='h-11 w-11 rounded-full'
                                />
                              </div>
                              <div className='ml-4'>
                                <div className='font-medium text-white'>
                                  {product.title}
                                </div>
                                <div className='mt-1 text-white'>
                                  {product.email}
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-5 text-sm text-white'>
                            <div className='text-white'>{product.category}</div>
                            <div className='mt-1 text-gray-500'>
                              {product.category}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-5 text-sm white'>
                            <div className='text-white'>
                              {product.subcategory}
                            </div>
                          </td>
                          <td className='whitespace-nowrap px-3 py-5 text-sm text-white'>
                            {product.company}
                          </td>
                          <td className='relative whitespace-nowrap py-5 pl-3 pr-4 text-right text-sm font-medium sm:pr-0'>
                            <Link
                              to={`/editproduct/${product._id}`}
                              className='text-indigo-600 hover:text-indigo-500'
                            >
                              Επεξεργασία
                              <span className='sr-only'>, {product.title}</span>
                            </Link>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default ListProductsPage;
