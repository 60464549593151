'use client';

import { PhotoIcon, PlusIcon } from '@heroicons/react/24/outline';
import { SlTrash } from 'react-icons/sl';
import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useProductsContext } from '../context/products_context';
//import { products_url as url } from '../utils/constants';

// const secondaryNavigation = [
//   { name: 'Account', href: '#', current: true },
//   { name: 'Notifications', href: '#', current: false },
//   { name: 'Billing', href: '#', current: false },
//   { name: 'Teams', href: '#', current: false },
//   { name: 'Integrations', href: '#', current: false },
// ];
const products_url = process.env.REACT_APP_PRODUCTS_URL;
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

const EditProductPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    single_product_loading: loading,
    single_product_error: error,
    single_product: product,
    product_update_loading,
    product_delete_loading,
    fetchSingleProduct,
    updateProduct,
    deleteProduct,
  } = useProductsContext();

  const data = new FormData();

  const [images, setImages] = useState([]);
  const [title, setTitle] = useState('');
  const [category, setCategory] = useState('');
  const [subcategory, setSubcategory] = useState('');
  const [company, setCompany] = useState('');
  const [description, setDescription] = useState('');
  const [details, setDetails] = useState([]);

  data.append('title', title);
  data.append('category', category);
  data.append('subcategory', subcategory);
  data.append('description', description);
  data.append('company', company);
  data.append('details', details);
  //data.append('images', images);

  const handleImages = () => {
    for (let i = 0; i < Array.from(images).length; i++) {
      data.append('images', images[i]);
    }
  };

  const updateImagesInfo = (event) => {
    setImages(event.target.files);
    console.log(event.target.files);
    //console.log(URL.createObjectURL(atob(event.target.files[0])));
  };
  const updateTitleInfo = (event) => {
    const value = event.target.value;
    setTitle(value);
  };
  const updateCompanyInfo = (event) => {
    const value = event.target.value;
    setCompany(value);
  };
  const updateCategoryInfo = (event) => {
    const value = event.target.value;
    setCategory(value);
  };
  const updateSubcategoryInfo = (event) => {
    const value = event.target.value;
    setSubcategory(value);
  };
  const updateDescriptionInfo = (event) => {
    const value = event.target.value;
    setDescription(value);
  };

  const handleAddDetails = (e) => {
    setDetails([...details, e.target.value]);
    console.log(e.target.value);
  };
  const handleDeleteDetails = (i) => {
    const deletedValue = [...details];
    deletedValue.splice(i, 1);
    setDetails(deletedValue);
  };
  const handleChangeDetails = (e, i) => {
    const onChangeValue = [...details];
    onChangeValue[i] = e.target.value;
    setDetails(onChangeValue);
  };

  useEffect(() => {
    if (Array.from(images)?.length >= 1) {
      for (let i = 0; i < Array.from(images)?.length; i++) {
        data.append('images', images[i]);
        console.log(images[i]);
      }
    }
    // eslint-disable-next-line
  }, [[images, title, category, subcategory, company, description, details]]);

  useEffect(() => {
    if (!localStorage.getItem('user')) {
      navigate('/login');
      console.log('login check');
    }

    // eslint-disable-next-line
  }, []);

  const main = async () => {
    const product = await fetchSingleProduct(`${products_url}${id}`);
    //const product = data.data.product;
    if (product) {
      setTitle(product.title);
      setCategory(product.category);
      setSubcategory(product.subcategory);
      setCompany(product.company);
      setDetails(product.details);
      setDescription(product.description);
      setImages(product.images);
      console.log(product.images);
    }
    // This will be executed after fetchData completes
  };
  const handleDelete = async () => {
    if (window.confirm('Are you sure to delete?')) {
      deleteProduct(`${products_url}${id}`);
      navigate('/productslist');
    }
  };

  useEffect(() => {
    main();
    //fetchSingleProduct(`${url}${id}`);
    // eslint-disable-next-line
  }, [id]);

  if (loading) {
    return (
      <div className='xl:pl-72'>
        <main className='grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8'>
          <div className='text-center'>
            <span className='text-white loading loading-ring loading-lg'></span>
          </div>
        </main>
      </div>
    );
  }
  if (error) {
    return (
      <div className='xl:pl-72'>
        <main className='grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8'>
          <div className='text-center'>
            <p className='text-base font-semibold text-indigo-600'>404</p>
            <h1 className='mt-4 text-balance text-5xl font-semibold tracking-tight text-white sm:text-7xl'>
              Η Σελίδα δεν βρεθηκε
            </h1>
            <p className='mt-6 text-pretty text-lg font-medium text-white sm:text-xl/8'>
              Το προϊόν δεν υπάρχει ή έχει διαγραφεί!
            </p>
            <div className='mt-10 flex items-center justify-center gap-x-6'>
              <Link
                to={'/'}
                className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
              >
                Δημιουργία Προϊόντος
              </Link>
              <Link
                to={'/productslis'}
                className='text-sm font-semibold text-white'
              >
                Επεξεργασία Προϊόντων <span aria-hidden='true'>&rarr;</span>
              </Link>
            </div>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div>
      <div className='xl:pl-72'>
        <main>
          <h1 className='sr-only'>Επεξεργασία Προϊόντος</h1>
          <div className='divide-y divide-white/5'>
            <div className='grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8'>
              <div>
                <h2 className='text-base/7 font-semibold text-white'>
                  Επεξεργασία Προϊόντος
                </h2>
                <p className='mt-1 text-sm/6 text-gray-400'></p>
              </div>

              <form
                className='md:col-span-2'
                onSubmit={updateProduct(data, id)}
              >
                <div className='grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6'>
                  <div className='col-span-full'>
                    <label
                      htmlFor='title'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Τίτλος
                    </label>
                    <div className='mt-2'>
                      <input
                        id='name'
                        name='name'
                        type='text'
                        autoComplete='off'
                        value={title}
                        onChange={updateTitleInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='col-span-full'>
                    <label
                      htmlFor='title'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Μάρκα
                    </label>
                    <div className='mt-2'>
                      <input
                        id='company'
                        name='company'
                        type='text'
                        autoComplete='off'
                        value={company}
                        onChange={updateCompanyInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Κατηγορία
                    </label>
                    <div className='mt-2'>
                      <input
                        id='category'
                        name='category'
                        type='text'
                        autoComplete='off'
                        value={category}
                        onChange={updateCategoryInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='last-name'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Υποκατηγορία
                    </label>
                    <div className='mt-2'>
                      <input
                        id='subcategory'
                        name='subcategory'
                        type='text'
                        autoComplete='off'
                        value={subcategory}
                        onChange={updateSubcategoryInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                  </div>

                  <div className='sm:col-span-3'>
                    <label
                      htmlFor='details'
                      className='block text-sm/6 font-medium text-white mb-2'
                    >
                      Χαρακτηριστικά
                    </label>
                    <button
                      type='button'
                      onClick={handleAddDetails}
                      className='rounded-full bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
                    >
                      <PlusIcon aria-hidden='true' className='h-5 w-5' />
                    </button>
                    {details?.map((detail, i) => (
                      <div className='mt-2 flex'>
                        <div className='flex-auto w-56'>
                          <input
                            id='details'
                            name='details'
                            type='text'
                            key={i}
                            autoComplete='off'
                            value={detail}
                            onChange={(e) => handleChangeDetails(e, i)}
                            className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                            required
                          />
                        </div>
                        <div className='flex-auto'>
                          <button
                            type='button'
                            className='ml-6 rounded-full bg-red-600 p-1 text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600'
                            onClick={() => handleDeleteDetails(i)}
                          >
                            <SlTrash aria-hidden='true' className='h-5 w-5' />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>

                  <div className='col-span-full'>
                    <label
                      htmlFor='about'
                      className='block text-sm/6 font-medium text-white'
                    >
                      Περιγραφή
                    </label>
                    <div className='mt-2'>
                      <textarea
                        id='description'
                        name='description'
                        rows={5}
                        value={description}
                        onChange={updateDescriptionInfo}
                        className='block w-full rounded-md border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm/6'
                        required
                      />
                    </div>
                    <p className='mt-3 text-sm/6 text-gray-400'>
                      Συμπληρώστε το πεδίο με αναλυτική περιγραφή του προϊόντος.
                    </p>
                  </div>

                  <div className='col-span-full'>
                    <label className='block text-sm/6 font-medium text-white'>
                      {images[0]?.url
                        ? Array.from(images)?.map((image) => {
                            return (
                              <>
                                <a
                                  href={image.url}
                                  target='_blank'
                                  rel='noreferrer'
                                  className='hover:text-indigo-600'
                                >
                                  {image.url}
                                </a>
                                <br />
                              </>
                            );
                          })
                        : Array.from(images)?.map((image) => {
                            return (
                              <div>
                                {image.name}
                                <br />
                              </div>
                            );
                          })}
                    </label>
                    <div className='mt-2 flex justify-center rounded-lg border border-dashed border-white/25 px-6 py-10'>
                      <div className='text-center'>
                        <PhotoIcon
                          aria-hidden='true'
                          className='mx-auto h-12 w-12 text-gray-500'
                        />
                        <div className='mt-4 flex text-sm/6 text-gray-400'>
                          <label
                            htmlFor='images'
                            className='relative cursor-pointer rounded-md bg-gray-900 font-semibold text-white focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-600 focus-within:ring-offset-2 focus-within:ring-offset-gray-900 hover:text-indigo-500'
                          >
                            <span>Ανεβάστε αρχείο</span>
                            <input
                              id='images'
                              name='images'
                              type='file'
                              className='sr-only'
                              onChange={updateImagesInfo}
                              multiple
                            />
                          </label>
                          <p className='pl-1'>or drag and drop</p>
                        </div>
                        <p className='text-xs/5 text-gray-400'>
                          PNG, JPG, GIF up to 10MB
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='mt-8 flex flex-wrap justify-between'>
                  <div className=''>
                    <button
                      type='submit'
                      className='rounded-md bg-indigo-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500'
                      disabled={
                        product_update_loading |
                        (Array.from(images)?.length < 0)
                      }
                    >
                      {product_update_loading ? (
                        <span className='loading loading-spinner loading-xs'></span>
                      ) : (
                        'Ενημέρωση Προϊόντος'
                      )}
                    </button>
                  </div>
                  <div className=''>
                    <button
                      type='button'
                      className='rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 block'
                      disabled={product_delete_loading}
                      onClick={handleDelete}
                    >
                      <SlTrash aria-hidden='true' className='h-5 w-5 inline' />
                      {product_delete_loading ? (
                        <span className='loading loading-spinner loading-xs'></span>
                      ) : (
                        'Διαγραφή Προϊόντος'
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default EditProductPage;
