import {
  USER_SIGNIN_BEGIN,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_ERROR,
  USER_REGISTER_BEGIN,
  USER_REGISTER_SUCCESS,
  USER_REGISTER_ERROR,
  UPDATE_PASSWORD_BEGIN,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_ERROR,
} from '../actions';

const user_reducer = (state, action) => {
  if (action.type === USER_SIGNIN_BEGIN) {
    return {
      ...state,
      user_loading: true,
      user_error: false,
    };
  }
  if (action.type === USER_SIGNIN_SUCCESS) {
    return {
      ...state,
      user_loading: false,
      user: action.payload,
    };
  }
  if (action.type === USER_SIGNIN_ERROR) {
    return {
      ...state,
      user_loading: false,
      user_error: action.payload,
    };
  }

  if (action.type === USER_REGISTER_BEGIN) {
    return {
      ...state,
      user_register_loading: true,
      user_register_error: false,
    };
  }
  if (action.type === USER_REGISTER_SUCCESS) {
    return {
      ...state,
      user_register_loading: false,
      user: action.payload,
    };
  }
  if (action.type === USER_REGISTER_ERROR) {
    return {
      ...state,
      user_register_loading: false,
      user_register_error: action.payload,
    };
  }

  if (action.type === UPDATE_PASSWORD_BEGIN) {
    return {
      ...state,
      password_update_loading: true,
      password_update_error: false,
    };
  }
  if (action.type === UPDATE_PASSWORD_SUCCESS) {
    return {
      ...state,
      password_update_loading: false,
      password_updated_message: action.payload,
    };
  }
  if (action.type === UPDATE_PASSWORD_ERROR) {
    return {
      ...state,
      password_update_loading: false,
      password_update_error: true,
      password_updated_message: action.payload,
    };
  }

  throw new Error(`No Matching "${action.type}" - action type`);
};

export default user_reducer;
