export const SIDEBAR_OPEN = 'SIDEBAR_OPEN';
export const SIDEBAR_CLOSE = 'SIDEBAR_CLOSE';
export const MOBILE_FILTERS_SIDEBAR_CLOSE = 'MOBILE_FILTERS_SIDEBAR_CLOSE';
export const MOBILE_FILTERS_SIDEBAR_OPEN = 'MOBILE_FILTERS_SIDEBAR_OPEN';

export const USER_SIGNIN_BEGIN = 'USER_SIGNIN_BEGIN';
export const USER_SIGNIN_SUCCESS = 'USER_SIGNIN_SUCCESS';
export const USER_SIGNIN_ERROR = 'USER_SIGNIN_ERROR';

export const UPDATE_PASSWORD_BEGIN = 'UPDATE_PASSWORD_BEGIN';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_ERROR = 'UPDATE_PASSWORD_ERROR';

export const USER_REGISTER_BEGIN = 'USER_REGISTER_BEGIN';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

export const GET_PRODUCTS_BEGIN = 'GET_PRODUCTS_BEGIN';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_ERROR = 'GET_PRODUCTS_ERROR';

export const GET_SINGLE_PRODUCT_BEGIN = 'GET_SINGLE_PRODUCT_BEGIN';
export const GET_SINGLE_PRODUCT_SUCCESS = 'GET_SINGLE_PRODUCT_SUCCESS';
export const GET_SINGLE_PRODUCT_ERROR = 'GET_SINGLE_PRODUCT_ERROR';

export const CREATE_PRODUCT_BEGIN = 'CREATE_PRODUCTS_BEGIN';
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCTS_SUCCESS';
export const CREATE_PRODUCT_ERROR = 'CREATE_PRODUCTS_ERROR';

export const UPDATE_PRODUCT_BEGIN = 'UPDATE_PRODUCTS_BEGIN';
export const UPDATE_PRODUCT_SUCCESS = 'UPDATE_PRODUCTS_SUCCESS';
export const UPDATE_PRODUCT_ERROR = 'UPDATE_PRODUCTS_ERROR';

export const DELETE_PRODUCT_BEGIN = 'DELETE_PRODUCTS_BEGIN';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCTS_SUCCESS';
export const DELETE_PRODUCT_ERROR = 'DELETE_PRODUCTS_ERROR';

export const UPDATE_TITLE_INFO = 'UPDATE_TITLE_INFO';
export const UPDATE_COMPANY_INFO = 'UPDATE_COMPANY_INFO';
export const UPDATE_CATEGORY_INFO = 'UPDATE_CATEGORY_INFO';
export const UPDATE_SUBCATEGORY_INFO = 'UPDATE_SUBCATEGORY_INFO';
export const UPDATE_DESCRIPTION_INFO = 'UPDATE_DESCRIPTION_INFO';
export const UPDATE_IMAGES_INFO = 'UPDATE_IMAGES_INFO';

export const LOAD_PRODUCTS = 'LOAD_PRODUCTS';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
